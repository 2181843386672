import React from "react"
import HeaderThree from "../common/header/HeaderThree"
import SEO from "../common/SEO"
import BannerThree from "../component/banner/BannerThree"
import ProjectTwo from "../component/project/ProjectTwo"
import SectionTitle from "../elements/section-title/SectionTitle"
import ColorSwitcher from "../elements/switcher/ColorSwitcher"
import BrandOne from "../component/brand/BrandOne"
import ProjectThree from "../component/project/ProjectThree"
import TestimonialTwo from "../component/testimonial/TestimonialTwo"
import FooterTwo from "../common/footer/FooterTwo"
import FormThree from "../component/contact/FormThree"

const PersonalPortfolio = () => {

  return (
    <>
      <SEO title="Melon Software GmbH"/>
      <main className="main-wrapper">
        <HeaderThree/>
        <BannerThree/>
        <div className="section-padding bg-color-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-address mb--30">
                  <SectionTitle
                    subtitle="§ 5 Abs. 1 TMG"
                    title="Impressum"
                    description=""
                    textAlignment="heading-light-left"
                    textColor=""
                  />
                  <div className="address-list">
                    <div className="address">
                      <h6 className="title">Anschrift</h6>
                      <p>Melon Software GmbH</p>
                      <p>Kronenstr. 28</p>
                      <p>44139 Dortmund</p>
                    </div>
                    <div className="address">
                      <h6 className="title">Geschäftsführer</h6>
                      <p>Helmut Kraiß<br/> Jannis Gebauer (Prokurist)</p>
                    </div>
                    <div className="address">
                      <h6 className="title">Email</h6>
                      <p>hi@melonsoftware.de</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-address" style={{marginTop: "160px"}}>
                  <div className="address">
                    <h6 className="title">Registergericht</h6>
                    <p>Amtsgericht München</p>
                  </div>
                  <div className="address">
                    <h6 className="title">Handelsregisternummer</h6>
                    <p>HRB 80279</p>
                  </div>
                  <div className="address">
                    <h6 className="title">Umsatzsteuer ID</h6>
                    <p>---</p>
                  </div>
                  <div className="address">
                    <h6 className="title">Steuernummer</h6>
                    <p>---</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default PersonalPortfolio

