import React from 'react';
import { Link } from 'react-router-dom';


const Logo = ({image}) => {
    return (
        <Link to={process.env.PUBLIC_URL + "/"}>
            <img className="light-version-logo" src={process.env.PUBLIC_URL + image} alt="logo" style={{height:"60px", width: "100%"}} />
            <img className="dark-version-logo" src={process.env.PUBLIC_URL + image} alt="logo" style={{height:"60px", width: "100%"}} />
            <img className="sticky-logo" src={process.env.PUBLIC_URL + image} alt="logo" style={{height:"60px", width: "100%"}} />
        </Link>
    )
}


export default Logo;